import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";

function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import { fetchFinanceList, fetchFinanceCount, financeWithdrawCheck } from "../../../api/partner";
import { fetchPropertyList } from "@/api/charge";
export default {
  name: "AgentFinanceWithdraw",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        date: "",
        status: "",
        keyword: ""
      },
      financeCount: {
        totalBalance: 0,
        waitAmount: 0,
        waitPayAmount: 0,
        paySuccessAmount: 0
      },
      bill_date: [],
      // 表格数据
      list: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      //搜索小区
      propertyLoading: false,
      propertyOptions: [],
      checkDialog: false,
      checkForm: {
        user: {}
      },
      checkStatus: 'check_pass'
    };
  },
  created: function created() {
    this.fetchList();
    this.fetchCount();
  },
  methods: {
    fetchCount: function fetchCount() {
      var _this = this;

      fetchFinanceCount(this.searchForm).then(function (res) {
        _this.financeCount = res.data;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    fetchList: function fetchList() {
      var _this2 = this;

      this.tableLoading = true;
      fetchFinanceList(this.searchForm).then(function (response) {
        _this2.list = response.data;
        _this2.totalPage = response.meta.total;
        _this2.tableLoading = false;
      }).catch(function (err) {
        _this2.tableLoading = false;

        _this2.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.fetchList();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.fetchList();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.fetchList();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //搜索小区
    remoteProperty: function remoteProperty(query) {
      var _this3 = this;

      if (query !== "") {
        this.propertyLoading = true;
        setTimeout(function () {
          _this3.propertyLoading = false;
          var data = {
            keyword: query
          };
          fetchPropertyList(data).then(function (res) {
            console.log(res);
            _this3.propertyOptions = res.data;
          });
        }, 100);
      } else {
        this.propertyOptions = [];
      }
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm.filter["start_time"] = val[0];
        this.searchForm.filter["end_time"] = val[1];
      } else {
        this.searchForm.filter["start_time"] = "";
        this.searchForm.filter["end_time"] = "";
      }
    },
    handleCheck: function handleCheck(row) {
      var _this4 = this;

      this.checkDialog = true;
      this.checkForm = row;
      this.$nextTick(function () {
        _this4.$refs["checkForm"].clearValidate();
      });
    },
    handleCheckBtn: function handleCheckBtn() {
      var _this5 = this;

      this.$refs["checkForm"].validate(function (valid) {
        if (valid) {
          var data = {
            id: _this5.checkForm.id,
            status: _this5.checkStatus,
            check_reason: _this5.checkStatus == 'check_failed' ? _this5.checkForm.check_reason : ''
          };
          financeWithdrawCheck(data).then(function (res) {
            _this5.checkDialog = false;

            var _iterator = _createForOfIteratorHelper(_this5.list),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;

                if (v.id === data.id) {
                  v.status = _this5.checkStatus;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }

            _this5.$message({
              type: "success",
              message: '审核完成'
            });
          }).catch(function (err) {
            console.error(err);

            _this5.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    }
  }
};